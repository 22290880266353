* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

@keyframes blink {
    50% {
        fill: transparent;
    }
}
.dot {
    animation: 1s blink infinite;
    fill: grey;
}
.dot:nth-child(2) {
    animation-delay: 250ms;
}
.dot:nth-child(3) {
    animation-delay: 500ms;
}
